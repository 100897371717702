import st from "../styles/items/Input.module.scss";
import { useEffect, useRef, useState, useMemo } from "react";
import ValueIcons from "./ValueIcons";
import { ClickCopy } from "@components/click-copy";
import ToolTip from "./ToolTip";

/**
 * @deprecated
 */
export default function InputItem(props) {
  let {
    //name value is the same as the state key
    name,
    value,
    type = "text",
    label = false,
    dispatch,
    method,
    step,
    min,
    //passes event to method
    eMethod,
    param,
    //Text box or regular
    inputType,
    //Default is FIELD
    dispatchType = "FIELD",
    size = 100,
    errors = false,
    error = false,
    errorMsg = "Custom Error",
    //For betting inputs
    inputSetup = false,
    rate,
    wallet = false,
    readOnly = false,
    //classes for input
    classType = [],
    //The buttons
    inlineButtons = false,
    inlineError = false,
    //alternative values that get displayed in the label
    convertValues = false,
    //Gives the inline icon
    specialBetSetter = false,
    //icon
    icon = false,
    IconItem = false,
    iconPosition = false,
    placeholder = "",
    valueIconCode = false,
    copy = false,
    onFocus = false,
    addPlaceHolder = false,
    toolTip = false,
    style = false,
    labelClass = "",
    outerRef = null,
    pointerCursor = false,
    isFileSelector = false,
    extraHeaderContent = false,
    autoFocus = false
  } = props;
  const inputObj = useMemo(() => {
    return {
      id: name,
      name,
      value: value,
      type: type,
      maxLength: size,
      placeholder: placeholder,
      autoFocus
    };
  }, [name, value, type, size, placeholder, autoFocus]);
  let [betValue, setBetValue] = useState(false);
  let [betIcon, setBetIcon] = useState();
  useEffect(() => {
    if (wallet && rate) {
      if (wallet.activeFiat) {
        setBetValue((value / rate[wallet.selectedFiat]).toFixed(8));
        setBetIcon(<ValueIcons type={"crypto"} wallet={wallet} code={wallet.selectedCrypto} />);
      } else {
        // //console.log("show btc");
        //Convert to fiat
        //Need to set Money icon
        setBetIcon(<ValueIcons type={"fiat"} wallet={wallet} />);
        setBetValue(wallet.selectedFiat + " " + (value * rate[wallet.selectedFiat]).toFixed(2));
      }
    }
  }, [wallet, rate, value]);

  //Hide the creation of this if there is no dispatch
  //Error handling on the form
  let timerEnabled = errors ? true : false;
  const timer = useRef(null);
  const initializeTimer = e => {
    return setTimeout(() => {
      dispatch({
        type: "ERRORCHECK",
        target: name,
        payload: e.target.value
      });
    }, 1000);
  };
  if (dispatch) {
    //Call disptach to update the values, send the payload
    inputObj.onChange = e => {
      if (timerEnabled) {
        if (timer.current) {
          clearTimeout(timer.current);
        }
        timer.current = initializeTimer(e);
      }
      // setInputValue(e.target.value);
      dispatch({
        type: dispatchType,
        target: name,
        payload: e.target.value
      });
    };
  }
  if (method) {
    if (type === "number") {
      inputObj.onChange = e => {
        //Prevents a non input
        method(e.target.value, param);
      };
    } else if (type === "file" && isFileSelector) {
      inputObj.onChange = e => {
        method(e.target.files[0], param);
      };
    } else {
      inputObj.onChange = e => {
        // setInputValue(e.target.value);
        method(e.target.value, param);
      };
    }
  }
  if (eMethod) {
    if (type === "number") {
      inputObj.onKeyPress = e => {
        var keyCode = e.keyCode || e.which;
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
          e.preventDefault();
        }
        if (keyCode === 46 && e.target.value.includes(".")) {
          e.preventDefault();
        }
      };
      inputObj.onChange = e => {
        let value = e.target.value;
        const length = value.split(".")[1] ? value.split(".")[1].length : 0;
        if (length > 2) {
          value = value.slice(0, -1);
        } else {
          value = value;
        }
        eMethod(value, param);
      };
    } else {
      inputObj.onChange = e => {
        const value = e.target.value;
        // setInputValue(value);

        eMethod(value, param);
      };
    }
  }

  //Numbers
  // if(type==="number" && step || min) {
  //      inputObj.step = step;
  //     inputObj.min = min ? min : 0;
  // }

  if (onFocus) {
    //Call disptach to update the values, send the payload
    inputObj.onFocus = e => {
      onFocus(param);
    };
  }

  //used to change step type depending on toggle
  //This goes inside input, but should be targeted at the wrapper
  let classes = `
        ${classType.map(item => st[item]).join(" ")}
        ${st["reset-file-input"]}
        ${pointerCursor ? st["cursor-pointer"] : ""}
    `;
  const renderInputDisplay = () => {
    if (inlineButtons || inputSetup === "inlineContent" || copy) {
      // <ConditionalToolTip toolTip={toolTip}>

      let returnValue = <div className={`
              ${st["inline-btn-container"]}
              ${inlineError ? st["inline-error"] : null}

              `}>
          {valueIconCode ? <div className={st["inline-value-icon"]}>{valueIconCode}</div> : null}

          <input id={name} className={wallet ? st["icon-space"] : null} {...inputObj} {...toolTip ? {
          onBlur() {
            if (addPlaceHolder) {
              addPlaceHolder();
            }
            toolTip?.toolTipMethod();
          }
        } : {}} />

          <div className={st["btn-container"]}>
            {/* Inline stuff */}
            {inlineButtons ? inlineButtons : null}

            {/* Copy btn */}
            {copy && value !== null && <ClickCopy value={value} />}
          </div>
        </div>;
      return toolTip ? <ToolTip {...toolTip}>{returnValue}</ToolTip> : returnValue;
    } else if (readOnly) {
      return <>
          <input value={value} className={st["read-only"]} readOnly autoComplete="off" {...inputObj} required />
        </>;
    } else if (toolTip) {
      return <>
          {icon ? <div className={st["icon"]}>{icon}</div> : null}
          <ToolTip {...toolTip}>
            <input className={classes} {...inputObj} required />
          </ToolTip>
        </>;
    } else {
      return <>
          {icon ? <div className={st["icon"]}>{icon}</div> : null}

          <input className={classes} {...inputObj} required ref={outerRef} />
        </>;
    }
  };
  return <div className={`${label ? st["icon-padding"] : null} ${st.inputWrapper} ${classes} ${errors[name]?.length || error ? st["error"] : ""}`} data-sentry-component="InputItem" data-sentry-source-file="InputItem.jsx">
      {label ? <div className={`${st["label-heading"]} ${st["labelClass"]}`}>
          {/* Errors will appear here */}
          <label htmlFor={name} className={st[labelClass]}>
            {label}
            {/* Will display errors */}
            {/* {errors ? errors[name]?.length ? errors[name].map((item, i ) => {return(<span className={st["error-item"]} key={i}>{item}</span>);}) : null : null}   */}
          </label>
        </div> : null}

      {extraHeaderContent ? extraHeaderContent : null}

      {IconItem && IconItem}
      {renderInputDisplay()}
    </div>;
}